import React from 'react';
import {Auth} from "aws-amplify";
import Alert from "./Alert";
import SignIn from "./SignIn";
import ConfirmSignUp from "./ConfirmSignUp";

class SignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            givenName: '',
            familyName: '',
            phoneNumber: '',
            address: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
    }

    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h5>Шинээр бүртгүүлэх</h5>
                        </div>
                        <div className="card-body">
                            <Alert message={this.state.error}/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-envelope"></i></span>
                                    <div className="form-floating">
                                        <input name="email" type="email" className="form-control" id="email" placeholder="email" required="required" onChange={this.handleChange} />
                                        <label htmlFor="email">И-мэйл хаяг</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-key"></i></span>
                                    <div className="form-floating">
                                        <input name="password" type="password" className="form-control" id="password" placeholder="password" required="required" onChange={this.handleChange} />
                                        <label htmlFor="password">Нууц үг</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-person-up"></i></span>
                                    <div className="form-floating">
                                        <input name="familyName" type="text" className="form-control" id="familyName" placeholder="familyName" required="required" onChange={this.handleChange} />
                                        <label htmlFor="familyName">Эцгийн нэр</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-person"></i></span>
                                    <div className="form-floating">
                                        <input name="givenName" type="text" className="form-control" id="givenName" placeholder="givenName" required="required" onChange={this.handleChange} />
                                        <label htmlFor="givenName">Өөрийн нэр</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-telephone"></i></span>
                                    <div className="form-floating">
                                        <input name="phoneNumber" type="tel" className="form-control" id="phoneNumber" placeholder="phoneNumber" required="required" onChange={this.handleChange} />
                                        <label htmlFor="phoneNumber">Утасны дугаар</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-geo-alt"></i></span>
                                    <div className="form-floating">
                                        <input name="address" type="text" className="form-control" id="address" placeholder="address" required="required" onChange={this.handleChange} />
                                        <label htmlFor="address">Оршин суугаа хаяг</label>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="button" className="btn px-4 mx-2" onClick={this.handleSignIn}>Болих</button>
                                    <button type="submit" className="btn btn-primary px-4">Бүртгүүлэх</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async handleSubmit(e) {
        e.preventDefault();

        try {
            let phoneNumber = this.state.phoneNumber.startsWith('+') ? this.state.phoneNumber : '+1'.concat(this.state.phoneNumber);

            let signUpResult = await Auth.signUp({
                username: this.state.email,
                password: this.state.password,
                attributes: {
                    given_name: this.state.givenName,
                    family_name: this.state.familyName,
                    phone_number: phoneNumber,
                    address: this.state.address
                }
            });
            if (signUpResult.userConfirmed) {
                this.props.app.setState({
                    activeComponent: <SignIn app={this.props.app}/>
                });
            } else {
                this.props.app.setState({
                    activeComponent: <ConfirmSignUp app={this.props.app} user={signUpResult.user.getUsername()}/>
                });
            }
        } catch (error) {
            this.setState({
                error: error.message
            })
        }
    }

    handleSignIn(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <SignIn app={this.props.app} />
        })
    }
}

export default SignUp;