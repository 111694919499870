import React from 'react';
import {Auth} from "aws-amplify";
import Alert from "./Alert";
import PackageList from "./PackageList";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <>
                <Alert message={this.state.error}/>
                <form onSubmit={this.handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="password">Шинэ нууц үг</label>
                        <input name="password" type="password" className="form-control" id="password" onChange={this.handleChange}/>
                    </div>
                    <button type="submit" className="btn btn-primary">Нууц үг солих</button>
                </form>
            </>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async handleSubmit(e) {
        e.preventDefault();

        try {
            await Auth.completeNewPassword(this.props.user, this.state.password);

            this.props.app.setState({
                activeComponent: <PackageList app={this.props.app}/>,
            });
        } catch (error) {
            this.setState({
                error: error.message
            })
        }
    }
}

export default ChangePassword;