import React from 'react';
import Header from "./Header";
import Home from "./Home";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            signedUser: null,
            activeComponent: <Home app={this} />
        };
    }

    render() {
        return (
            <>
                <Header app={this} />
                <div className="container">
                    {this.state.activeComponent}
                    <div className="row mt-5 mb-3 pt-2 copyright d-print-none">
                        <div className="col-auto">
                            &copy; 2023 Genesis 5 Logistics Inc.
                        </div>
                    </div>
                </div>
            </>
        );
    }

    isAdmin() {
        try {
            return this.state.signedUser.signInUserSession.idToken.payload['cognito:groups'].includes('Admin');
        } catch (e) {
            console.log(e.message);
            return false;
        }
    }
}

export default App;