import React from 'react';
import {API} from "aws-amplify";
import PackageUpdate from "./PackageUpdate";
import PrintLabel from "./PrintLabel";

class PackageList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            packages: []
        }

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handlePrintLabel = this.handlePrintLabel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    render() {
        let packages;
        if (this.state.packages) {
            packages = this.state.packages.map((p) => {
                let s = this.mapStatus(p.status);
                return <tr key={p.pId}>
                    <td>{p.pId}</td>
                    {
                        p && this.props.app.isAdmin() &&
                        <td>{p.sender.firstName.concat(' ', p.sender.lastName)}</td>
                    }
                    <td>{p.receiver.firstName.concat(' ', p.receiver.lastName)}</td>
                    <td>{p.weight}</td>
                    <td><span className={"badge " + s.badgeClass}>{s.title}</span></td>
                    <td>
                        {
                            p.status !== 'deleted' && p.status !== 'delivered' && this.props.app.isAdmin() &&
                            <button className="btn btn-sm mx-2" onClick={(e) => this.handleUpdate(e, p)}><i
                                className="bi bi-pencil"></i></button>
                        }
                        {
                            p.status !== 'deleted' && p.status !== 'delivered' &&
                            <button className="btn btn-sm" onClick={(e) => this.handleDelete(e, p)}><i
                                className="bi bi-trash"></i></button>
                        }
                        {
                            p.status === 'received' &&
                            <button className="btn btn-sm mx-2" onClick={(e) => this.handlePrintLabel(e, p)}><i
                                className="bi bi-upc-scan"></i></button>
                        }
                    </td>
                </tr>
            });
        }

        return (
            <>
                {
                    this.props.app.isAdmin() &&
                    <div className="row mb-3">
                        <div className="col-3">
                            <form onSubmit={this.handleSearch}>
                                <div className="input-group">
                                    <input name="phone" type="search" className="form-control" id="phone" placeholder="Илгээгчийн утасны дугаар" value={this.state.phone} onChange={this.handleChange} />
                                    <button type="submit" className="btn"><i className="bi bi-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-header">
                                <h5>Илгээмжүүд</h5>
                            </div>
                            <div className="card-body">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th>Дугаар</th>
                                        {
                                            this.props.app.isAdmin() &&
                                            <th>Илгээгч</th>
                                        }
                                        <th>Хүлээн авагч</th>
                                        <th>Жин</th>
                                        <th>Төлөв</th>
                                        <th>Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {packages}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount() {
        this.fetchPackages();
    }

    fetchPackages() {
        const init = this.state.phone ? {
            queryStringParameters: {
                phone: this.state.phone
            }
        } : null;

        API.get('package', '/package', init)
            .then(response => {
                this.setState({
                    packages: response.Items
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleUpdate(e, p) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <PackageUpdate app={this.props.app} uId={p.uId} pId={p.pId} />
        })
    }

    handleDelete(e, p) {
        e.preventDefault();

        if (!window.confirm('Бүртгэсэн илгээмжийг устгах уу?')) return;

        try {
            const init = {
                body: {
                    uId: p.uId,
                    pId: p.pId
                }
            }

            API.put('package', '/package', init)
                .then(response => {
                    this.fetchPackages();
                })
                .catch(error => {
                    this.setState({
                        error: error
                    })
                });
        } catch (error) {
            this.setState({
                error: error
            })
        }
    }

    handlePrintLabel(e, p) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <PrintLabel app={this.props.app} uId={p.uId} pId={p.pId} />
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSearch(e) {
        e.preventDefault();

        this.fetchPackages();
    }

    mapStatus(s) {
        switch (s) {
            case 'created':
                return {
                    title: 'Бүртгэсэн',
                    badgeClass: 'text-bg-primary'
                }
            case 'deleted':
                return {
                    title: 'Устгасан',
                    badgeClass: 'text-bg-danger'
                }
            case 'received':
                return {
                    title: 'Хүлээн авсан',
                    badgeClass: 'text-bg-secondary'
                }
            case 'arrived':
                return {
                    title: 'Буусан',
                    badgeClass: 'text-bg-secondary'
                }
            case 'delivered':
                return {
                    title: 'Хүлээлгэж өгсөн',
                    badgeClass: 'text-bg-success'
                }
            default:
                return {
                    title: s,
                    badgeClass: 'text-bg-secondary'
                }
        }
    }
}

export default PackageList;