import React from 'react';
import {Auth} from "aws-amplify";
import Alert from "./Alert";
import SignIn from "./SignIn";

class ConfirmSignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            email: this.props.user,
            code: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReSend = this.handleReSend.bind(this);
    }

    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h5>И-мэйл баталгаажуулах</h5>
                        </div>
                        <div className="card-body">
                            <Alert message={this.state.error}/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-envelope"></i></span>
                                    <div className="form-floating">
                                        <input name="email" type="email" className="form-control" id="email" placeholder="email" value={this.props.user} onChange={this.handleChange} />
                                        <label htmlFor="email">И-Мэйл</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-123"></i></span>
                                    <div className="form-floating">
                                        <input name="code" type="password" className="form-control" id="code" placeholder="code" onChange={this.handleChange}/>
                                        <label htmlFor="code">Баталгаажуулах код</label>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary mb-3 px-4">Баталгаажуулах</button><br/>
                                    <a href="#" onClick={this.handleReSend}>Код дахин илгээх</a>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async handleSubmit(e) {
        e.preventDefault();

        try {
            await Auth.confirmSignUp(this.state.email, this.state.code);
            this.props.app.setState({
                activeComponent: <SignIn app={this.props.app}/>
            });
        } catch (error) {
            this.setState({
                error: error.message
            })
        }
    }

    async handleReSend(e) {
        e.preventDefault();

        try {
            await Auth.resendSignUp(this.state.email);
        } catch (error) {
            this.setState({
                error: error.message
            })
        }
    }
}

export default ConfirmSignUp;