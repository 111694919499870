import React from 'react';
import Item from "./Item";

class ItemList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [{}]
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    render() {
        return (
            <div className="my-3">
                <div className="row">
                    <div className="col">
                        <label><b>Барааны мэдээлэл</b></label>
                    </div>
                </div>
                {this.state.items.map((item, i) =>
                    <div className="row" key={i}>
                        <div className="col">
                            <Item name={i} onChange={this.handleChange} />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-sm" onClick={(e) => this.handleRemove(e, i)}><i className="bi bi-bag-dash-fill"></i></button>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col text-end">
                        <button className="btn btn-sm" onClick={this.handleAdd}><i className="bi bi-bag-plus-fill"></i> Бараа нэмэх</button>
                    </div>
                </div>
            </div>
        )
    }

    handleChange(e) {
        this.state.items[e.target.name] = e.target.value

        this.setState({
            items: this.state.items
        })

        this.props.onChange({
            target: {
                name: this.props.name,
                value: this.state.items
            }
        });
    }

    handleAdd(e) {
        e.preventDefault();

        this.state.items.push({});

        this.setState({
            items: this.state.items
        })
    }

    handleRemove(e, i) {
        e.preventDefault();

        this.state.items.splice(i, 1);

        this.setState({
            items: this.state.items
        })
    }
}

export default ItemList;