import React from 'react';
import {API} from "aws-amplify";
import Person from "./Person";
import PackageList from "./PackageList";
import Alert from "./Alert";
import ItemList from "./ItemList";

class PackageAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: '',
            paymentMethod: '',
            comment: '',
            sender: null,
            receiver: null,
            items: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePackageList = this.handlePackageList.bind(this);
    }

    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-xxl-8">
                    <div className="card">
                        <div className="card-header">
                            <h5>Илгээмж бүртгэх</h5>
                        </div>
                        <div className="card-body">
                            <Alert message={this.state.error}/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-truck"></i></span>
                                    <div className="form-floating">
                                        <select name="type" className="form-select" aria-label="Илгээмжийн төрөл" onChange={this.handleChange} required="required">
                                            <option value="">Сонгох</option>
                                            <option value="air">Агаарын илгээмж</option>
                                            <option value="ground">Газрын илгээмж</option>
                                        </select>
                                        <label htmlFor="type">Илгээмжийн төрөл</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-credit-card"></i></span>
                                    <div className="form-floating">
                                        <select name="paymentMethod" className="form-select" aria-label="Төлбөрийн хэлбэр" onChange={this.handleChange} required="required">
                                            <option value="">Сонгох</option>
                                            <option value="cash">Бэлэн мөнгө</option>
                                            <option value="card">Карт</option>
                                            <option value="zelle">Zelle</option>
                                            <option value="receiver">Хүлээн авагч төлөх</option>
                                        </select>
                                        <label htmlFor="paymentMethod">Төлбөрийн хэлбэр</label>
                                    </div>
                                </div>
                                <Person title="Илгээгч" name="sender"
                                        firstName={this.props.app.state.signedUser.attributes.given_name}
                                        lastName={this.props.app.state.signedUser.attributes.family_name}
                                        phone={this.props.app.state.signedUser.attributes.phone_number}
                                        address={this.props.app.state.signedUser.attributes.address}
                                        onChange={this.handleChange} />
                                <Person title="Хүлээн авагч" name="receiver" onChange={this.handleChange} />
                                <ItemList name="items" onChange={this.handleChange} />
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-chat-dots"></i></span>
                                    <div className="form-floating">
                                        <textarea name="comment" rows="3" className="form-control" id="comment" placeholder="comment" onChange={this.handleChange}></textarea>
                                        <label htmlFor="comment">Нэмэлт мэдээлэл</label>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="button" className="btn px-4 mx-2" onClick={this.handlePackageList}>Болих</button>
                                    <button type="submit" className="btn btn-primary px-4">Бүртгэх</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async handleSubmit(e) {
        e.preventDefault();

        try {
            const init = {
                body: this.state
            }
            API.post('package', '/package', init)
                .then(response => {
                    this.props.app.setState({
                        activeComponent: <PackageList app={this.props.app}/>
                    });
                })
                .catch(error => {
                    this.setState({
                        error: error.message
                    })
                });
        } catch (error) {
            this.setState({
                error: error.message
            })
        }
    }

    handlePackageList(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <PackageList app={this.props.app} />
        })
    }
}

export default PackageAdd;