import React from 'react';

class Person extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: this.props.firstName ? this.props.firstName : '',
            lastName: this.props.lastName ? this.props.lastName : '',
            phone: this.props.phone ? this.props.phone : '',
            address: this.props.address ? this.props.address : ''
        }

        this.handleChange = this.handleChange.bind(this);

        this.props.onChange({
            target: {
                name: this.props.name,
                value: this.state
            }
        });
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        <label><b>{this.props.title}</b></label>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <div className="input-group">
                            <span className="input-group-text"><i className="bi bi-person-up"></i></span>
                            <div className="form-floating">
                                <input name="lastName" type="text" className="form-control" id="lastName" placeholder="lastName" required="required" value={this.state.lastName} onChange={this.handleChange} />
                                <label htmlFor="lastName">Эцгийн нэр</label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group">
                            <span className="input-group-text"><i className="bi bi-person"></i></span>
                            <div className="form-floating">
                                <input name="firstName" type="text" className="form-control" id="firstName" placeholder="firstName" required="required" value={this.state.firstName} onChange={this.handleChange} />
                                <label htmlFor="firstName">Өөрийн нэр</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <div className="input-group">
                            <span className="input-group-text"><i className="bi bi-telephone"></i></span>
                            <div className="form-floating">
                                <input name="phone" type="tel" className="form-control" id="phone" placeholder="phone" required="required" value={this.state.phone} onChange={this.handleChange} />
                                <label htmlFor="phone">Утасны дугаар</label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group">
                            <span className="input-group-text"><i className="bi bi-geo-alt"></i></span>
                            <div className="form-floating">
                                <input name="address" type="text" className="form-control" id="address" placeholder="address" required="required" value={this.state.address} onChange={this.handleChange} />
                                <label htmlFor="address">Оршин суугаа хаяг</label>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    handleChange(e) {
        this.state[e.target.name] = e.target.value

        this.setState({
            [e.target.name]: e.target.value
        })

        this.props.onChange({
            target: {
                name: this.props.name,
                value: this.state
            }
        });
    }
}

export default Person;