import React from 'react';

class Item extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: '',
            price: 0
        }

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <div className="row mb-3">
                <div className="col-9">
                <div className="input-group">
                        <span className="input-group-text"><i className="bi bi-box"></i></span>
                        <div className="form-floating">
                            <input name="type" type="text" className="form-control" id="type" placeholder="type" required="required" onChange={this.handleChange} />
                            <label htmlFor="type">Барааны төрөл</label>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="input-group">
                        <span className="input-group-text"><i className="bi bi-currency-dollar"></i></span>
                        <div className="form-floating">
                            <input name="price" type="number" className="form-control" id="price" placeholder="price" required="required" onChange={this.handleChange} />
                            <label htmlFor="price">Үнэлгээ</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.state[e.target.name] = e.target.value

        this.setState({
            [e.target.name]: e.target.value
        })

        this.props.onChange({
            target: {
                name: this.props.name,
                value: this.state
            }
        });
    }
}

export default Item;