import React from 'react';
import {Auth} from "aws-amplify";
import SignIn from "./SignIn";
import Home from "./Home";
import PackageList from "./PackageList";
import PackageAdd from "./PackageAdd";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
        this.handlePackageList = this.handlePackageList.bind(this);
        this.handlePackageAdd = this.handlePackageAdd.bind(this);
    }

    render() {
        return (
            <div className="navbar navbar-expand-lg d-print-none mb-5">
                <div className="container-fluid">
                    <button className="navbar-brand" onClick={this.handleClick}>
                        <img src="logo.jpeg" alt="Genesis 5" height="60"/>
                    </button>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {!this.props.app.state.signedUser &&
                                <li className="nav-item">
                                    <button className="nav-link" onClick={this.handleSignIn}>
                                        <i className="bi bi-person-circle"></i> Нэвтрэх</button>
                                </li>
                            }
                            {this.props.app.state.signedUser &&
                                <li className="nav-item">
                                    <button className="nav-link" onClick={this.handlePackageList}>
                                        <i className="bi bi-box-seam-fill"></i> Илгээмжүүд</button>
                                </li>
                            }
                            {this.props.app.state.signedUser &&
                                <li className="nav-item">
                                    <button className="nav-link" onClick={this.handlePackageAdd}>
                                        <i className="bi bi-plus-square-fill"></i> Илгээмж бүртгэх</button>
                                </li>
                            }
                            {this.props.app.state.signedUser &&
                                <li className="nav-item">
                                    <button className="nav-link" onClick={this.handleSignOut}>
                                        <i className="bi bi-x-octagon-fill"></i> Гарах</button>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    handleClick(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <Home app={this.props.app} />
        })
    }

    handleSignIn(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <SignIn app={this.props.app} />
        })
    }

    async handleSignOut(e) {
        e.preventDefault();

        if (!window.confirm('Гарахдаа итгэлтэй байна уу?')) return;

        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }

        this.props.app.setState({
            signedUser: null,
            activeComponent: <SignIn app={this.props.app} />,
        })
    }

    handlePackageList(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <PackageList app={this.props.app} />
        })
    }

    handlePackageAdd(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <PackageAdd app={this.props.app} />
        })
    }
}

export default Header;