import React from 'react';
import {Auth} from "aws-amplify";
import PackageList from "./PackageList";
import Alert from "./Alert";
import ChangePassword from "./ChangePassword";
import SignUp from "./SignUp";

class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSignUp = this.handleSignUp.bind(this);
    }
    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h5>Нэвтрэх</h5>
                        </div>
                        <div className="card-body">
                            <Alert message={this.state.error}/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-envelope"></i></span>
                                    <div className="form-floating">
                                        <input name="email" type="email" className="form-control" id="email" placeholder="email" required="required" onChange={this.handleChange} />
                                        <label htmlFor="email">И-Мэйл</label>
                                    </div>
                                </div>
                                <div className="input-group mb-2">
                                    <span className="input-group-text"><i className="bi bi-key"></i></span>
                                    <div className="form-floating">
                                        <input name="password" type="password" className="form-control" id="password" placeholder="password" required="required" onChange={this.handleChange} />
                                        <label htmlFor="password">Нууц үг</label>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <a>Нууц үгээ мартсан?</a><br/>
                                    <button type="submit" className="btn btn-primary mt-3 mb-3 px-4">Нэвтрэх</button><br/>
                                    <a href="#" onClick={this.handleSignUp}>Бүртгүүлэх</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async handleSubmit(e) {
        e.preventDefault();

        try {
            let user = await Auth.signIn(this.state.email, this.state.password);

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.props.app.setState({
                    activeComponent: <ChangePassword app={this.props.app} user={user}/>
                });
            } else {
                this.props.app.setState({
                    signedUser: user,
                    activeComponent: <PackageList app={this.props.app}/>
                });
            }
        } catch (error) {
            this.setState({
                error: error.message
            })
        }
    }

    handleSignUp(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <SignUp app={this.props.app} />
        })
    }
}

export default SignIn;