import React from 'react';

class Home extends React.Component {
    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-header">
                                <h5>Бидний тухай</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Genesis 5 компани нь АНУ-н Чикаго хотоос агаар болон газарын ачаа тогтмол илгээж байна.
                                    Мөн онлайн захиалга авч байна.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <div className="card">
                            <div className="card-header">
                                <h5>Үйлчилгээ</h5>
                            </div>
                            <div className="card-body">
                                <div className="card-text">
                                    <div className="row">
                                        <div className="col">
                                            <h6>АНУ - Монгол</h6>
                                            <table className="table table-hover table-sm">
                                                <tbody>
                                                <tr>
                                                    <td>Агаар ачаа</td>
                                                    <td>1кг</td>
                                                    <td>$13</td>
                                                </tr>
                                                <tr>
                                                    <td>Газрын ачаа</td>
                                                    <td>Large</td>
                                                    <td>$50</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>X-Large</td>
                                                    <td>$70</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col">
                                            <h6>Монгол - АНУ</h6>
                                            <table className="table table-hover table-sm">
                                                <tbody>
                                                <tr>
                                                    <td>Агаар ачаа</td>
                                                    <td>1кг</td>
                                                    <td>65,000₮</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <div className="card">
                            <div className="card-header">
                                <h5>Холбоо барих</h5>
                            </div>
                            <div className="card-body">
                                <div className="card-text">
                                    <div className="row">
                                        <div className="col">
                                            <h6>АНУ</h6>
                                            <table className="table table-borderless">
                                                <tbody>
                                                <tr>
                                                    <td className="text-end"><i className="bi bi-geo-alt-fill"></i></td>
                                                    <td>
                                                        Genesis 5 Logistics Inc<br />
                                                        702 W Algonquin Rd<br />
                                                        Arlington Heights, IL 60004
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end"><i className="bi bi-telephone-fill"></i></td>
                                                    <td>
                                                        (847) 508-1934
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end"><i className="bi bi-calendar-event-fill"></i></td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <table className="table table-hover table-sm">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>Mon-Fri</td>
                                                                        <td>9:00 AM - 6:00 PM</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Sat</td>
                                                                        <td>9:00 AM - 5:00 PM</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Sun</td>
                                                                        <td>closed</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col">
                                            <h6>Монгол</h6>
                                            <table className="table table-borderless">
                                                <tbody>
                                                <tr>
                                                    <td className="text-end"><i className="bi bi-geo-alt-fill"></i></td>
                                                    <td>
                                                        Генесис 5 ХХК<br />
                                                        Чингэлтэй дүүрэг 3-р хороо<br />
                                                        2-р 40 мянгат 13-р байр 33 тоот
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end"><i className="bi bi-telephone-fill"></i></td>
                                                    <td>8038-1934</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end"><i className="bi bi-calendar-event-fill"></i></td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <table className="table table-hover table-sm">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Даваа</td>
                                                                            <td>амарна</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Мягмар-Бямба</td>
                                                                            <td>10:00 - 18:00</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Ням</td>
                                                                            <td>11:00 - 16:00</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;