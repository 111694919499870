import React from 'react';

class Alert extends React.Component {
    render() {
        if (!this.props.message) return null;

        return (
            <div className="alert alert-danger">{this.props.message}</div>
        );
    }
}

export default Alert;