import React from 'react';
import {API} from "aws-amplify";
import PackageList from "./PackageList";
import Alert from "./Alert";

class PrintLabel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            package: undefined
        }

        this.handlePrint = this.handlePrint.bind(this);
        this.handlePackageList = this.handlePackageList.bind(this);
    }

    render() {
        if (!this.state.package) return;

        let p = this.state.package;
        const rate = 13;

        let usd = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            useGrouping: true,
            maximumFractionDigits: 0
        });

        return (
            <div className="row print-label">
                <div className="col">
                    <div className="card">
                        <div className="card-header d-print-none">
                            <h5>Шошго хэвлэх</h5>
                        </div>
                        <div className="card-body">
                            <Alert message={this.state.error}/>
                            <div className="row">
                                <div className="col">
                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <td colSpan="2"><b>CP72</b></td>
                                                <td><h5>{p.pId}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><b>FROM:</b></td>
                                                <td>Name:</td>
                                                <td>{p.sender.firstName.concat(' ', p.sender.lastName)}</td>
                                            </tr>
                                            <tr>
                                                <td>Address:</td>
                                                <td colSpan="2">{p.sender.address}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone:</td>
                                                <td colSpan="2">{p.sender.phone}</td>
                                            </tr>
                                            <tr>
                                                <td><b>TO:</b></td>
                                                <td>Name:</td>
                                                <td>{p.receiver.firstName.concat(' ', p.receiver.lastName)}</td>
                                            </tr>
                                            <tr>
                                                <td>Address:</td>
                                                <td colSpan="2">{p.receiver.address}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone:</td>
                                                <td colSpan="2">{p.receiver.phone}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <td colSpan="4"><b>CN23 Customs Declaration</b></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">Detailed Description</td>
                                                <td>Quantity</td>
                                                <td>Net Wt (kg)</td>
                                                <td>Value ($)</td>
                                                <td></td>
                                            </tr>
                                            {p.items.map((item, i) =>
                                                <tr>
                                                    <td colSpan="2">
                                                        <input type="text" defaultValue={item.type} />
                                                    </td>
                                                    <td><input type="text" defaultValue="1" size="2" /></td>
                                                    <td></td>
                                                    <td className="text-end">
                                                        <input type="text" defaultValue={usd.format(item.price)} size="6" />
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>Rate(kg)</td>
                                                <td className="text-end">
                                                    <input type="text" defaultValue={usd.format(rate)} size="6" />
                                                </td>
                                                <td>Total Wt</td>
                                                <td>
                                                    <input type="text" defaultValue={p.weight} size="4" />
                                                </td>
                                                <td>Pay Type</td>
                                                <td>{p.paymentMethod}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"></td>
                                                <td>Total Paid</td>
                                                <td className="text-end">
                                                    <input type="text" defaultValue={usd.format(rate * p.weight)} size="6" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-auto">
                                    <img src="logo.jpeg" width="200" alt="Logo" />
                                    <div className="text-center">
                                        <h5>USA</h5>
                                        702 W Algonquin Rd<br/>
                                        Arlington Heights, IL 60005<br/>
                                        <br/>
                                        (847) 508-1934<br/>
                                        <br/>
                                        <h5>MGL</h5>
                                        Чингэлтэй дүүрэг 3-р хороо<br/>
                                        2-р 40 мянгат 13-р байр 33 тоот<br/>
                                        8038-1934, 8810-6441
                                    </div>
                                </div>
                            </div>
                            <div className="text-center d-print-none">
                                <button type="button" className="btn px-4 mx-2" onClick={this.handlePackageList}>Болих</button>
                                <button type="button" className="btn btn-primary px-4" onClick={this.handlePrint}>Хэвлэх</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.fetchPackages();
    }

    fetchPackages() {
        const init = {
            queryStringParameters: {
                uId: this.props.uId,
                pId: this.props.pId
            }
        }

        API.get('package', '/package', init)
            .then(response => {
                this.setState({
                    package: response.Items[0]
                })
            })
            .catch(error => {
                this.setState({
                    error: error.message
                })
            });
    }

    handlePrint(e) {
        e.preventDefault();

        window.print();
    }

    handlePackageList(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <PackageList app={this.props.app} />
        })
    }
}

export default PrintLabel;