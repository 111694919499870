import React from 'react';
import {API} from "aws-amplify";
import PackageList from "./PackageList";
import Alert from "./Alert";

class PackageUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uId: this.props.uId,
            pId: this.props.pId,
            weight: '',
            status: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePackageList = this.handlePackageList.bind(this);
    }

    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-xxl-8">
                    <div className="card">
                        <div className="card-header">
                            <h5>Илгээмж засварлах</h5>
                        </div>
                        <div className="card-body">
                            <Alert message={this.state.error}/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="input-group">
                                            <span className="input-group-text"><i className="bi bi-123"></i></span>
                                            <div className="form-floating">
                                                <input name="weight" type="number" className="form-control" id="weight" placeholder="weight" value={this.state.weight} required="required" onChange={this.handleChange} />
                                                <label htmlFor="weight">Жин</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text"><i className="bi bi-truck"></i></span>
                                            <div className="form-floating">
                                                <select name="status" className="form-select" value={this.state.status} onChange={this.handleChange} required="required">
                                                    <option value="">Сонгох</option>
                                                    <option value="created">Бүртгэсэн</option>
                                                    <option value="received">Хүлээн авсан</option>
                                                    <option value="arrived">Буусан</option>
                                                    <option value="delivered">Хүлээлгэж өгсөн</option>
                                                </select>
                                                <label htmlFor="status">Төлөв</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col text-center">
                                        <button type="button" className="btn px-4 mx-2" onClick={this.handlePackageList}>Болих</button>
                                        <button type="submit" className="btn btn-primary px-4">Хадгалах</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.fetchPackages();
    }
    fetchPackages() {
        const init = {
            queryStringParameters: {
                uId: this.state.uId,
                pId: this.state.pId
            }
        }

        API.get('package', '/package', init)
            .then(response => {
                var item = response.Items[0];

                this.setState({
                    weight: item.weight ? item.weight : '',
                    status: item.status ? item.status : ''
                })
            })
            .catch(error => {
                this.setState({
                    error: error.message
                })
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async handleSubmit(e) {
        e.preventDefault();

        try {
            const init = {
                body: this.state
            }
            API.put('package', '/package/update', init)
                .then(response => {
                    this.props.app.setState({
                        activeComponent: <PackageList app={this.props.app}/>
                    });
                })
                .catch(error => {
                    this.setState({
                        error: error.message
                    })
                });
        } catch (error) {
            this.setState({
                error: error.message
            })
        }
    }

    handlePackageList(e) {
        e.preventDefault();

        this.props.app.setState({
            activeComponent: <PackageList app={this.props.app} />
        })
    }
}

export default PackageUpdate;